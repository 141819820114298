import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Switch, Route } from 'react-router-dom';
import Card from "./../components/Card/Index";
import { DialogActions, Divider } from '@mui/material';
import call_btn from '../../assets/call_btn.png';
import close_btn from '../../assets/close_btn.png';
import menu_toggle from '../../assets/menu_toggle.png';
import menu_toggle_black from '../../assets/menu_toggle_black.png';
import closeIcon from "../../assets/CloseIcon.png";
import cartWhite from "../../assets/cart-white.png";
import cartBlack from "../../assets/cart-black.png";
import instagramIcon from '../../assets/contact-insta.png'
import twitterIcon from '../../assets/contact-twitter.png'
import searchIcon from '../../assets/search-icon.png'
import whatsappGreen from '../../assets/whatsapp.png'
import greenCheck from '../../assets/green-check-mark.png'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useStyles from '../components/Card/styles';
import MerchantContactUs from "../components/MerchantContactUs/MerchantContactUs";
import { Constants } from "../utils/Constants";
import { useLocation } from 'react-router-dom';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../redux/reduxStore";
import ProductPage from './ProductPage/ProductPage';
import ApiService from "../utils/ApiService";
import LoadingSpinner from "../components/loadingSpinner/loadingSpinner";

const carousel = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000, // 4 seconds
  appendDots: dots => (
    <ul style={{ margin: '0' }}>
      {dots.slice(0, 4)} {/* Display only the first 4 dots */}
    </ul>
  ),
};

export default function Homepage(props) {

  const classes = useStyles();

  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [mApp, setMApp] = useState('');
  const [cartVisible, setCartVisible] = useState(false);
  const [msgToWhatsapp, setMsgToWhatsapp] = useState('');
  const [openMenuTab, setOpenMenuTab] = useState(false);
  const { menuBarStyle, aboutUsPopupStyle, websiteThemeColors, optionsPopupStyle, requestTimedOutError } = Constants;
  const [shopNameContainerHeight, setShopNameContainerHeight] = useState(0);
  const [menuOption, setMenuOption] = useState(0);
  const [aboutUs, setAboutUs] = useState(false);
  const location = useLocation();
  const fullurl = localStorage.getItem("fullurl");
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.counter.totalCount);
  const whatsappOpen = useSelector((state) => state.counter.whatsappOpen);
  const openPhone = useSelector((state) => state.counter.openPhone);
  const pathname = location.pathname;
  var domainName = window.location.hostname;
  const [rewardText, setRewardText] = useState('Points');
  const userSessionId = localStorage.getItem("sessionId");
  const [changeStore, setChangeStore] = useState(false);
  const multipleStores = useSelector((state) => state.counter.multipleStores);
  const [selectedStoreMId, setSelectedStoreMId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const merchantSelected = useSelector((state) => state.counter.merchantSelected);

  let merchantDetails = JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails;
  if (merchantDetails && JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails?.loyaltyEnabled)
    localStorage.setItem("loyaltyPoints", merchantDetails?.loyaltyPoints)
  console.log("merDetailsHome", merchantDetails)

  const cartData = JSON.parse(localStorage.getItem("cartData"))

  const imagelists = merchantDetails && merchantDetails.image_list.length > 0 ? merchantDetails.image_list.map((data) => data) : "";

  const shopinsidephotos = merchantDetails && merchantDetails.shop_inside_photos.length > 0 ? merchantDetails.shop_inside_photos.map((data) => data) : "";

  const picurls = merchantDetails && merchantDetails.picurl !== "" ? [merchantDetails.picurl] : "";

  console.log("shopinsidephotos", shopinsidephotos);

  const populatedarray = [...picurls, ...shopinsidephotos, ...imagelists];
  console.log("populatedarray", populatedarray);

  const myDivRef = useRef(null);
  const callRef = useRef(null);
  const mainRef = useRef(null);
  const rewardTextRef = useRef(null);
  const searchInputRef = useRef(null);
  var mainMobileVisible = true;

  const indexToSwap = merchantDetails.mobile_numbers_enable_on_website.indexOf(merchantDetails.mainmobilenumber);

  // Check if the valueToCheck is in the array
  if (indexToSwap !== -1) {
    // Make a copy of the array to avoid directly mutating the state
    const newArray = [...merchantDetails.mobile_numbers_enable_on_website];

    // Swap the first element with the element containing the valueToCheck
    const temp = newArray[0];
    newArray[0] = newArray[indexToSwap];
    newArray[indexToSwap] = temp;
    merchantDetails.mobile_numbers_enable_on_website = newArray

    // Update the state with the new array
  } else {
    mainMobileVisible = false
  }
  const allEmptyStrings = merchantDetails.mobile_numbers_enable_on_website.every((element) => {
    // Check if element is not null or undefined
    if (element != null) {
      // Trim the element and check if it's an empty string
      return element.trim() === '';
    } else if (element === null) {
      // If element is null or undefined, consider it as an empty string
      return true
    } else {
      return false
    }
  });
  const firstNonEmptyIndex = merchantDetails.mobile_numbers_enable_on_website.findIndex((element) => element?.trim() !== '');

  const websiteTheme = merchantDetails.webisteBanner;
  const themeColor = merchantDetails.color;
  // const themeColor = 'white';
  // const websiteTheme = greenBG;

  useEffect(() => {
    if (mApp) {
      document.body.classList.add('non-clickable');
    } else {
      document.body.classList.remove('non-clickable');
    }
  }, [mApp]);

  useEffect(() => {
    if (searchInput.length === 1) {
      window.scrollTo(0, 0);

    }
  }, [searchInput])

  useLayoutEffect(() => {
    const updateHeight = () => {
      // Introduce a slight delay before getting the height
      setTimeout(() => {
        // Get the height of the shop-name-container
        const shopNameContainer = document.querySelector('.shop-name-container');
        if (shopNameContainer) {
          setShopNameContainerHeight(shopNameContainer.offsetHeight);
        }
      }, 50); // Adjust the delay as needed
    };

    // Call the updateHeight function initially
    if (searchInput === '') {
      updateHeight();
    }

    // Use both resize and orientationchange events as fallbacks
    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('orientationchange', updateHeight);
    };
  }, [pathname, searchInput, changeStore, merchantSelected]);

  // useEffect(() => {
  //   window.addEventListener("message", messageHandler, false);
  //   function messageHandler(event) {
  //     const { action, key, value } = event.data
  //     console.log("ACTION", action, "KEY", key, "Value", value)
  //     // if (action == 'returnData'){
  //     //   useData(key, value)
  //     // }
  //   }
  // }, [])


  useEffect(() => {
    const urls = window.location.href;
    var url = new URL(urls);
    var merapp = url.searchParams.get('ismApp')
    setMApp(merapp);
    localStorage.removeItem("in-store-order-id");
    localStorage.removeItem("in-store-amount");
  }, []);

  useEffect(() => {
    let rewardTextInterval = null;
    if (merchantDetails?.loyaltyEnabled) {
      rewardTextInterval = setInterval(() => {
        if (localStorage.getItem("sessionId")) {
          if (rewardTextRef.current.textContent === 'Points')
            setRewardText(`${localStorage.getItem("loyaltyPoints")}`);
          else
            setRewardText('Points');
        }
      }, 5000)
    }
    return () => {
      clearInterval(rewardTextInterval);
    };
  }, [userSessionId])

  const arrays = [...picurls, ...shopinsidephotos, ...imagelists];
  const finalarray = [...arrays];

  const handleWhatsapp = (phoneNumber) => {
    window.open(
      `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=Enquiry from customer`
    );
  };

  const handleClearSearch = () => {
    setSearchInput('');
    searchInputRef.current.focus();
  }

  const handleCartClick = () => {
    if (location.pathname === `${localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/contact` : '/contact'}`) {
      console.log("CART INSIDE")
      localStorage.setItem('cartFromContact', '1');
      history.push(`/${localStorage.getItem("fullurl")}`)
    } else if (pathname.includes('/product')) {
      if (localStorage.getItem("User")) {
        history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/cart` : '/cart')
      } else {
        dispatch(setState({ key: "loginPage", value: true }));
        dispatch(setState({ key: "iframeLoader", value: true }));
      }
    } else {
      setCartVisible(true);
    }
  }

  function hasSingleNonEmptyValue(arr) {
    const nonEmptyValues = arr.filter(value => value !== '');
    return nonEmptyValues.length === 1;
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchInputRef.current.focus();
    }
  }
  // merchantDetails.storedescription = false

  const getSelectedMerchantDetails = async () => {
    let token = null;
    if (localStorage.getItem('sessionId')) {
      token = localStorage.getItem('sessionId');
    }
    const data = {
      merchantId: selectedStoreMId,
      ulatitude: '16.43',
      ulongitude: '74.59'
    }
    try {
      setIsLoading(true);
      const result = await ApiService.getMerchantDataForCatalogue(data, token);
      if (result?.code === requestTimedOutError) {
        alert(result?.message);
      } else if (result?.data?.code == 0) {
        setChangeStore(false);
        localStorage.removeItem("cartData");
        localStorage.removeItem("extraInstructions");
        document.title = result.data.merchantDetails.shopname;
        if (result.data.merchantDetails.subscription_flag <= 1) {
          window.location.href = 'https://refer.chattybao.com/app'
        } else {
          const currentPath = window.location.pathname;
          if (currentPath.includes('/cid=')) {
            // Get the current URL
            let currentUrl = window.location.href;

            // Remove the /cid=... part
            let updatedUrl = currentUrl.replace(/\/cid=[^\/]+/, '');

            // Update the URL without reloading the page
            window.history.replaceState(null, null, updatedUrl);
          }
          localStorage.removeItem("fromContact");
          localStorage.setItem("merchantdetails", JSON.stringify(result.data));
          localStorage.setItem("selected-store-id", result?.data?.merchantDetails?.merchantid);
          const multiStoreCart = JSON.parse(localStorage.getItem("multi-store-cart"));
          if (multiStoreCart) {
            const storeCart = multiStoreCart.find(data => data.merchantId === result?.data?.merchantDetails?.merchantid);
            if (storeCart && storeCart?.cartData.length !== 0) {
              localStorage.setItem("cartData", JSON.stringify(storeCart?.cartData))
            } else {
              localStorage.removeItem("cartData");
            }
          }
        }
        dispatch(setState({ key: "merchantSelected", value: true }));
      } else {
        alert(result?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeStore = () => {
    setChangeStore(true);
    setSelectedStoreMId(merchantDetails?.merchantid);
    let mulStores = [...multipleStores];
    const index = mulStores.findIndex(obj => obj.merchantid === localStorage.getItem("selected-store-id"));

    if (index !== -1) {
      // Remove the object from its current position
      const [targetObject] = mulStores.splice(index, 1);

      // Insert the object at the 0th index
      mulStores.unshift(targetObject);
    }
    dispatch(setState({ key: "multipleStores", value: mulStores }));
  }

  return (
    <div className="main_container" ref={mainRef} style={{ margin: "0px 0px 0px 0px", position: 'relative' }}>
      {isLoading && (
        <LoadingSpinner />
      )}
      <div className="data_container">
        {/* TOP-BAR */}
        {(multipleStores.length <= 1 || merchantSelected) && (
          <div className="top-bar">
            <img src={websiteTheme} className="tab-green-bg" alt="NA" />
            <div className="toggle-search-container">
              <img onClick={() => setOpenMenuTab(true)} src={themeColor === websiteThemeColors.black ? menu_toggle_black : menu_toggle} className="menu-toggle" alt="Menu" />
              <div ref={myDivRef} className={'search_bar'} >
                <input value={searchInput} ref={searchInputRef} type="text" className={'non_fixed_search'} onKeyDown={handleKeyDown} onChange={(e) => { history.push(`/${localStorage.getItem("fullurl")}`); setSearchInput(e.target.value); }} placeholder="Search any item" />
                <img src={searchIcon} className="search_icon" alt="search" />
                <img onClick={handleClearSearch} src={close_btn} className={searchInput !== '' ? 'cross-btn-visible' : 'cross-btn-invisible'} alt="NA" />
              </div>
              {merchantDetails?.loyaltyEnabled && (
                <div onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards` : '/rewards')} className="rewards-btn-container cursor-pointer">
                  <p className="rewards-rupee-icon">₹</p>
                  <p ref={rewardTextRef} className="points-text">{rewardText}</p>
                </div>
              )}
              <div onClick={handleCartClick} className="cart_icon">
                <img src={themeColor === websiteThemeColors.black ? cartBlack : cartWhite} className="top-bar-right-icons" alt="cart" />
                {cartData && cartData.length !== 0 && totalCount > 0 && (
                  <div className="cart_count_container">
                    <p className="cart_count">{totalCount}</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div hidden={searchInput !== ''} className="top-bar-right-icons-container">
            
          </div> */}
          </div>
        )}

        {console.log("MerDet", merchantDetails)}

        {searchInput === '' && (
          <div className="shopname-greenbg-container">
            <img src={websiteTheme} className="green-bg-shopname" style={{ height: `${shopNameContainerHeight + 8}px` }} alt="NA" />
            <div className="shop-name-container">
              {(
                merchantDetails.storedescription &&
                merchantDetails.storedescription !== 'null' &&
                merchantDetails.storedescription !== 'undefined'
              ) ? (
                <p className={`first-image-shop-name ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>
                  {merchantDetails.shopname}
                </p>
              ) : (
                <p className={`shop-name-no-description ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>
                  {merchantDetails.shopname}
                </p>
              )}

              {(
                merchantDetails.storedescription &&
                merchantDetails.storedescription !== 'null' &&
                merchantDetails.storedescription !== 'undefined' &&
                !pathname.includes('/product/')
              ) && (
                  <>
                    <div className={themeColor === websiteThemeColors.black ? 'shop-name-divider-black' : 'shop-name-divider'} />
                    <div className="about-us-container">
                      <p onClick={() => setAboutUs(true)} className={`merchant-about-us ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>{merchantDetails.storedescription}</p>
                      <FontAwesomeIcon onClick={() => setAboutUs(true)} color={themeColor === websiteThemeColors.black ? '#414042' : 'white'} style={{ width: '40px' }} icon={faChevronDown} />
                    </div>
                  </>

                )}
            </div>
          </div>
        )}

        {(merchantSelected && searchInput === '') && (
          <div className="selected-store-container cursor-pointer" onClick={handleChangeStore}>
            <p className="selected-store-address m-0">{merchantDetails?.addressline1}, {merchantDetails?.addressline2}</p>
            <div className="change-store-box">
              <p className="change-store-text m-0">Change Store</p>
              <FontAwesomeIcon className="change-store-down-arrow" icon={faChevronDown} />
            </div>
          </div>
        )}

        {searchInput === '' && !pathname.includes('/product') && (
          <div>
            <Slider {...carousel}>
              {finalarray.filter(dat => dat !== '')
                .map((data, index) => (
                  <div>
                    <div>
                      <img
                        src={data}
                        alt="img"
                        className="carousel-image"
                        height={363}
                      />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        )}

        {(multipleStores.length !== 0 && multipleStores.length !== 1 && !merchantSelected) ? (
          <div className="multi-store-container">
            <p className="multi-store-header">Select Store to get started</p>
            <div className="multi-store-divider" />
            <div className="multi-store-boxes-container">
              {multipleStores.map((data, index) => (
                <div key={index} onClick={() => setSelectedStoreMId(data?.merchantid)} className={`multi-store-box cursor-pointer ${selectedStoreMId === data?.merchantid && 'selected-store'}`}>
                  <p className="multi-store-shop-name-header m-0">{data?.shopname}</p>
                  <div className="multi-store-shop-name-divider" />
                  <p className="multi-store-shop-name-text m-0">{data?.addressline1}, {data?.addressline2}</p>
                  {selectedStoreMId === data?.merchantid &&
                    <img src={greenCheck} className="multi-store-green-check" alt="NA" />
                  }
                </div>
              ))}
            </div>
            <center>
              <button disabled={!selectedStoreMId} onClick={getSelectedMerchantDetails} className={`multi-store-confirm-btn ${!selectedStoreMId && 'confirm-btn-disabled'}`}>Confirm</button>
            </center>
          </div>
        ) : (
          <Switch>
            <Route path={(domainName !== 'devwebsite.chattybao.com' && domainName !== 'localhost' && domainName !== 'www.chattybao.com' && domainName !== 'chattybao.com') ? '/product/:productId/:other' : `/${localStorage.getItem('fullurl')}/product/:productId/:other`} >
              <ProductPage />
            </Route>
            {/* <Route path='/rewards' >
            <Rewards />
          </Route> */}
            <Route path={fullurl ? `/${localStorage.getItem("fullurl")}/contact` : '/contact'}>
              <MerchantContactUs merchantDetails={{
                latitude: merchantDetails.latitude,
                longitude: merchantDetails.longitude,
                addressline1: merchantDetails.addressline1,
                addressline2: merchantDetails.addressline2,
                city: merchantDetails.city,
                pincode: merchantDetails.pincode,
                opentime: merchantDetails.openTime,
                opentime2: merchantDetails.openTime2,
                closetime: merchantDetails.closeTime,
                closetime2: merchantDetails.closeTime2,
                weeklyoff: merchantDetails.weeklyoff,
                mainmobilenumber: merchantDetails.mainmobilenumber,
                teammobilenumbers: merchantDetails.teammobilenumbers,
                mobile_numbers_enable_on_website: merchantDetails.mobile_numbers_enable_on_website,
                socialMedia: merchantDetails.socialMedia,
                displayAddress: merchantDetails.displayAddress,
                merchant_email: merchantDetails.merchant_email,
                fssaiNo: merchantDetails.lincenseno
              }} />
            </Route>
            <Route path={`/${localStorage.getItem("fullurl")}`}>
              <Card
                key={merchantDetails?.merchantid}
                merchantdetails={merchantDetails}
                searchInput={searchInput}
                cartVisible={cartVisible}
                setCartVisible={setCartVisible}
                menuOption={menuOption}
                setMenuOption={setMenuOption}
              />
            </Route>
          </Switch>
        )}

        {/* Menu Bar */}

        <Dialog
          open={openMenuTab}
          className=""
          fullScreen
          classes={{
            paper: classes.menuBar,
          }}
          PaperProps={{ sx: menuBarStyle }}
          onClose={() => setOpenMenuTab(!openMenuTab)}
        >
          <img
            src={closeIcon}
            className="menu-bar-close-icon"
            onClick={() => {
              setOpenMenuTab(!openMenuTab);
            }}
            alt="close"
          />
          <DialogContent>
            <div className="menu-bar-content">
              <div className="menu-bar-routes-container">
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(0); }}>Home</p>
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(1); }}>Bestsellers</p>
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(2); }}>Products</p>
              </div>
              <p className="menu-bar-route" onClick={() => { fullurl ? history.push(`/${localStorage.getItem("fullurl")}/contact`) : history.push('/contact'); setOpenMenuTab(!openMenuTab); setMenuOption(3); }}>Contact Us</p>
              <div className="menu-bar-details-container">
                <p>{merchantDetails.shopname}</p>
                {(merchantDetails.displayAddress && merchantDetails.displayAddress.addressline1 !== "") ? (
                  <>
                    <p className="address-text-contact">{merchantDetails.displayAddress.addressline1}, {merchantDetails.displayAddress.addressline2}</p>
                    <p className="address-text-contact">{merchantDetails.displayAddress.city} - {merchantDetails.displayAddress.pincode}</p>
                  </>
                ) : (
                  <>
                    <p className="address-text-contact">{merchantDetails.addressline1}, {merchantDetails.addressline2}</p>
                    <p className="address-text-contact">{merchantDetails.city} - {merchantDetails.pincode}</p>
                  </>
                )}
                {merchantDetails.merchant_email && merchantDetails.merchant_email.showOnWebsite === true && (
                  <p className="email-text">{merchantDetails.merchant_email.email}</p>
                )}
                {allEmptyStrings !== true && (
                  <div className='call-icon-text-container'>
                    <img src={call_btn} className='call-icon' alt='social' />
                    <div className="phonenumbers-container">
                      {merchantDetails.mobile_numbers_enable_on_website.length !== 0 && merchantDetails.mobile_numbers_enable_on_website.map((data, index) => (
                        <>
                          {data && (
                            <a href={`tel:+91${data}`} className='ph-number-link phone-number-text-menu'>{firstNonEmptyIndex === index ? data : `, ${data}`}</a>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}
                <div className='social-icon-text-container'>
                  {merchantDetails.socialMedia && merchantDetails.socialMedia.instagramUrl !== "" && (
                    <a href={`https://www.instagram.com/${merchantDetails.socialMedia.instagramUrl}/`}>
                      <img src={instagramIcon} className='contact-social-icon' alt='social' />
                    </a>
                  )}
                  {merchantDetails.socialMedia && merchantDetails.socialMedia.twitterUrl !== "" && (
                    <a href={`https://twitter.com/${merchantDetails.socialMedia.twitterUrl}/`}>
                      <img src={twitterIcon} className='contact-social-icon' alt='social' />
                    </a>
                  )}
                  {/* <img src={fbContact} className='contact-social-icon' alt='social' />
                  <img src={youtubeContact} className='contact-social-icon' alt='social' /> */}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* Call layover */}

        <Dialog classes={{ paper: classes.botm_dialog }} open={openPhone || whatsappOpen} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => { dispatch(setState({ key: "openPhone", value: false })); dispatch(setState({ key: "whatsappOpen", value: false })); }}>
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              dispatch(setState({ key: "openPhone", value: false }))
              dispatch(setState({ key: "whatsappOpen", value: false }))
            }}
            alt="close"
          />
          <p className="call-contact-us-header">
            {whatsappOpen ? 'WhatsApp' : openPhone ? 'Contact Us' : ''}
          </p>
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
            <div className="call-contact-container">
              {(!merchantDetails.mobile_numbers_enable_on_website || merchantDetails.mobile_numbers_enable_on_website.length === 0) && (
                <p className="no-contact-number-text">Business has not provided any contact number</p>
              )}
              {merchantDetails.mobile_numbers_enable_on_website && merchantDetails.mobile_numbers_enable_on_website.length !== 0 && (
                merchantDetails.mobile_numbers_enable_on_website.map((data, index) => {
                  return (
                    data ? (
                      <>
                        {console.log("MERRRR,", merchantDetails.mobile_numbers_enable_on_website)}
                        <div onClick={whatsappOpen ? () => handleWhatsapp(data) : () => window.location.href = `tel:+91${data}`} className="phone-owner-container">
                          <div style={{ width: '180px' }}>
                            {whatsappOpen ? (
                              <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                                <img src={whatsappGreen} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                                <p className="phone_call_text phone-number-text">{data}</p>
                              </div>
                            ) : (
                              <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                                <img src={call_btn} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                                <p className="phone_call_text phone-number-text">{data}</p>
                              </div>
                            )}

                          </div>
                          {mainMobileVisible ? (
                            <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                          ) : (
                            <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                          )}
                        </div>
                        <div hidden={hasSingleNonEmptyValue(merchantDetails.mobile_numbers_enable_on_website)}>
                          <Divider className="phone-number-divider" />
                        </div>
                      </>

                    ) : ''
                  )
                })
              )}
            </div>
          </DialogContent>
        </Dialog>

        {/* About Us pop up */}
        <Dialog
          open={aboutUs}
          className=""
          fullScreen
          classes={{
            paper: classes.aboutUs,
          }}
          PaperProps={{ sx: aboutUsPopupStyle }}
          onClose={() => setAboutUs(!aboutUs)}
        >
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              setAboutUs(!aboutUs);
            }}
            alt="close"
          />
          <DialogContent>
            <p className="about-us-header">About Us</p>
            <p className="about-us-content">{merchantDetails.storedescription}</p>
          </DialogContent>
        </Dialog>

        {/* change store pop up */}
        <Dialog classes={{ paper: classes.botm_dialog }} open={changeStore} PaperProps={{ sx: optionsPopupStyle }} onClose={() => setChangeStore(false)}>
          {isLoading && (
            <LoadingSpinner />
          )}
          <div className="dialog_instruction_options">
            Select Store
            <img
              src={close_btn}
              className="close_btn2 cursor-pointer"
              onClick={() => setChangeStore(false)}
              alt="close"
            />
          </div>

          <DialogContent className="store-options-boxes-container">
            {multipleStores.map((data, index) => (
              <div onClick={() => setSelectedStoreMId(data?.merchantid)} key={index} className={`change-store-pop-up-box cursor-pointer ${selectedStoreMId === data?.merchantid && 'selected-store'}`}>
                <p className="multi-store-shop-name-header m-0">{data?.shopname}</p>
                <div className="multi-store-shop-name-grey-divider" />
                <p className="multi-store-shop-name-text m-0">{data?.addressline1}, {data?.addressline2}</p>
                {selectedStoreMId === data?.merchantid &&
                  <img src={greenCheck} className="multi-store-green-check" alt="NA" />
                }
              </div>
            ))}
          </DialogContent>

          <DialogActions className="store-options-confirm-btn-container">
            <button disabled={selectedStoreMId === localStorage.getItem("selected-store-id")} onClick={getSelectedMerchantDetails} className={`multi-store-confirm-btn ${selectedStoreMId === localStorage.getItem("selected-store-id") && 'confirm-btn-disabled'}`}>Confirm</button>
          </DialogActions>

        </Dialog>

      </div>
      {console.log("mulT", multipleStores)}
      {console.log("sel", merchantSelected)}

    </div>
  );
}
