
const APIConfig = {
    // baseUrl: "https://dev.chattybao.com/v1/", //TEST
    // baseUrl1: "https://dev.chattybao.com/v2/", //TEST
    // baseUrl2: "https://dev.chattybao.com/v3/", //TEST
    // iframeUrl: "https://devwebsite.chattybao.com/login", //TEST
    // gmapApiKey: 'AIzaSyCRoSa6CgZXRZYyVw9KSe0sghOkTyHACJ0', //TEST
    // webUrl: 'https://devwebsite.chattybao.com', //TEST

    baseUrl : "https://api.chattybao.com/v1/", //PROD
    baseUrl1 : "https://api.chattybao.com/v2/", //PROD
    baseUrl2 : "https://api.chattybao.com/v3/", //PROD
    iframeUrl: "https://chattybao.com/login", //PROD
    gmapApiKey: 'AIzaSyDgzOSXP40P4tXXs1a2uUQ4dblD9pXKdEY', //PROD
    webUrl: 'https://chattybao.com', //PROD


    getMerchantDataForCatalogue: 'getMerchantDataForCatalogue',
    viewMerchant: 'viewMerchant?id=',
    getUserAddresses: 'getUserAddresses',
    getUserLogisticCharge: 'getUserLogisticCharge',
    gMapCall: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
    makePayment: 'makePayment',
    webUserLogin: 'webUserLogin',
    updateUserAddress: 'updateUserAddress',
    getChannelDetailsv3: 'getChannelDetailsv3',
    getMerchUserChannel: 'getMerchUserChannel',
    trackOrder: 'trackOrder',
    getMerchantS1Categories: 'getMerchantS1Categories',
    getS1CategoriesProducts: 'getS1CategoriesProducts',
    searchMerchantCatalogue: 'searchMerchantCatalogue',
    getUserAddressFromLatLong: 'getUserAddressFromLatLong',
    getHelpDeskNumber: 'getHelpDeskNumber',
    getOrderPaymentDetails: 'getOrderPaymentDetails',
    generateHashcodeForWeb: 'generateHashcodeForWeb',
    getProductDetails: 'getProductDetails',
    sendUserLoginOTP: 'sendUserLoginOTP',
    verifyUserLoginOTP: 'verifyUserLoginOTP',
    resendUserLoginOTP: 'resendUserLoginOTP',
    webUserLoginNew: 'webUserLoginNew',
    getRedeemablePoints: 'getRedeemablePoints',
    createPaymentLinkForInStore: 'createPaymentLinkForInStore',
    getLoyaltyOrderDetailsForUser: 'getLoyaltyOrderDetailsForUser',
    getCustomerMerchantLoyaltyInfo: 'getCustomerMerchantLoyaltyInfo',
    getLoyaltyTransactionsForUser: 'getLoyaltyTransactionsForUser',
    getMerchantInfoFromDomain: 'getMerchantInfoFromDomain',
}

export { APIConfig };