import React, { useState, useEffect, useRef } from 'react'
import './CartPage.css'
import { faChevronLeft, faMapMarkerAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { Card } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import CloseIcon from "@mui/icons-material/Close";
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';

import veg_icon from '../../../assets/veg.png';
import egg_icon from '../../../assets/egg.png';
import non_veg_icon from '../../../assets/nonveg.png';
import call_btn from '../../../assets/call_btn.png';
import { useHistory } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import close_icon from "../../../assets/close_btn.png";
import useStyles from '../../components/Card/styles';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { setState } from "../../redux/reduxStore.js";
import { Constants } from '../../utils/Constants.js';
import { APIConfig } from '../../utils/apiConfig.js';
import { commonFunctions } from '../../utils/CommonFunctions.js';

const CartPage = (props) => {
  const merchantDetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails }
  let mainMobileVisible = true;

  const indexToSwap = merchantDetails.data.mobile_numbers_enable_on_website.indexOf(merchantDetails.data.mainmobilenumber);

  // Check if the valueToCheck is in the array
  if (indexToSwap !== -1) {
    // Make a copy of the array to avoid directly mutating the state
    const newArray = [...merchantDetails.data.mobile_numbers_enable_on_website];

    // Swap the first element with the element containing the valueToCheck
    const temp = newArray[0];
    newArray[0] = newArray[indexToSwap];
    newArray[indexToSwap] = temp;
    merchantDetails.data.mobile_numbers_enable_on_website = newArray

    // Update the state with the new array
  } else {
    mainMobileVisible = false
  }

  const totalPrice = useSelector((state) => state.counter.totalPrice);
  const oldTotalPrice = useSelector((state) => state.counter.oldTotalPrice);
  const savings = useSelector((state) => state.counter.savings);
  const totalCount = useSelector((state) => state.counter.totalCount);
  const shopOff = merchantDetails.data.shopClosed;
  const isCheckout = merchantDetails.data.eligibility_of_checkout;
  const businessName = merchantDetails.data.shopname;
  const merchId = merchantDetails.data.merchantid;
  const PhoneNumber = merchantDetails.data.mainmobilenumber;
  const picUrl = merchantDetails.data.picurl;
  let l3 = merchantDetails && merchantDetails.data.categoryl3;
  let isFruitsAndVegMerchant = "";
  if ("95f732e0-737b-11ec-9806-93163a488dc9" == l3 || "3d795333-84ae-11ec-a0d0-795c4aba562b" == l3) {
    isFruitsAndVegMerchant = "FRUITS";
  } else if ("8650b2d0-737b-11ec-9806-93163a488dc9" == l3) {
    isFruitsAndVegMerchant = "GROCERY";
  }
  const [cartData, setCartData] = useState(JSON.parse(localStorage.getItem("cartData")));
  const [customInstructions, setCustomInstructions] = useState(['']);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infoPopUp, setInfoPopUp] = useState(false);
  const [showSelectAddress, setShowSelectAddress] = useState(false);
  const [instructionIndex, setInstructionIndex] = useState(null);
  const [instructionFromCart, setInstructionFromCart] = useState(false);
  const [instructionFromHome, setInstructionFromHome] = useState(false);
  const [variantIndex, setVariantIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [instruction, setInstruction] = useState('');
  const [logisticData, setLogisticData] = useState('');
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [priorityAddress, setPriorityAddress] = useState('');
  const [addressData, setAddressData] = useState([]);
  const [whatsappLayover, setWhatsappLayover] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [rewardsCheck, setRewardsCheck] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addressPopUpStyle, toastStyle } = Constants;
  const payuFormRef = useRef(null);
  const inputRefs = useRef({});
  const { currency } = commonFunctions;
  const multipleStores = useSelector((state) => state.counter.multipleStores);

  useEffect(() => {
    async function fetchData() {
      await getChannelDetailsv3();
      getaddressesAndLogistics();
    }
    fetchData();
  }, [])

  useEffect(() => {  // check for live order when page is focussed
    const getLiveOrder = () => {
      if (!document.hidden) {
        getChannelDetailsv3()
      }
    };

    document.addEventListener('visibilitychange', getLiveOrder);

    return () => {
      document.removeEventListener('visibilitychange', getLiveOrder);
    };
  }, []);

  const modalopen = (id, instruction, fromVariants) => {
    if (fromVariants) {
      const cartIndex = cartData.map((item) => item.id).indexOf(id);
      setInstructionIndex(cartIndex);
      setInstructionFromCart(true);
      setInstructionFromHome(true);

    } else {
      for (let i = 0; i < cartData?.length; i++) {
        if (cartData[i].variants && cartData[i].variants.length !== 0) {
          for (let j = 0; j < cartData[i].variants.length; j++) {
            let data = (cartData[i].variants[j])
            if (data.varientId === id) {
              setVariantIndex(j);
              setInstructionIndex(i)
              break;
            }
          }
        }
      }
      setInstructionFromCart(true);
      setInstructionFromHome(false);
      setInstructionFromCart(false);
    }
    setModalVisible(true);
    setInstruction(instruction);
  };

  const getLogisticCharge = async (value) => {
    setIsLoading(true);
    let data = {}

    const cartData = localStorage.getItem('cartData')
    let cartDataItems = cartData ? JSON.parse(cartData) : []
    let newCart = cartDataItems;
    newCart.map(ndata => {
      ndata.category = ndata.s1category;
      if (!ndata?.rate) {
        ndata.rate = ndata?.price
      }
    })
    console.log("newCart", newCart)
    console.log('cartData getUserLogisticCharge called: ', cartData);
    if (value && value !== 1) {
      data = {
        merchantNo: PhoneNumber,
        order_amount: value.val ? value.val : 0,
        ulatitude: value.lat,
        ulongitude: value.lng,
        eligibility_of_checkout: isCheckout,
        is_from_app: 1,
        cartItems: newCart,
        useLoyaltyPoints: false,
      }
    } else if (value && value === 1) { // get logistics including loyalty points
      data = {
        merchantNo: PhoneNumber,
        order_amount: totalPrice !== "" ? totalPrice : 0,
        ulatitude: currentLat,
        ulongitude: currentLng,
        eligibility_of_checkout: isCheckout,
        is_from_app: 1,
        cartItems: cartDataItems,
        useLoyaltyPoints: true,
      }
    } else {
      data = {
        merchantNo: PhoneNumber,
        order_amount: totalPrice !== "" ? totalPrice : 0,
        ulatitude: currentLat,
        ulongitude: currentLng,
        eligibility_of_checkout: isCheckout,
        is_from_app: 1,
        cartItems: cartDataItems,
        useLoyaltyPoints: false,
      }
    }
    const token = localStorage.getItem('sessionId')
    console.log('cart data======> ', '  ' + JSON.stringify(data))

    await ApiService.getUserLogisticCharge(data, token)
      .then(async result => {
        console.log('data======> ', '  ' + JSON.stringify(result.data))
        if (result?.data?.code == 0) {
          let data = {}
          data = {
            deliveryCharge: result.data.data.totalLogistic,
            govtTax: result.data.data.govt_charge,
            payAmount: result.data.data.amtToPay,
            cashback: result.data.data.userOffer,
            cgst: result.data.data.cgstOnLogistic,
            sgst: result.data.data.sgstOnLogistic,
            logisticCharge: result.data.data.logisticCharge,
            distance: result.data.data.distanceInKM,
            deliveryMsg: result.data.data.btnMsg,
            orderMsg: result.data.data.orderMsg,
            userOffer: result.data.data.userOffer ? result.data.data.userOffer : 0,
            nightChargeMsg: result.data.data.nightChargeMsg,
            nightCharges: result.data.data.nightCharges,
            offerText: result.data.data.offerText ? result.data.data.offerText : 0,
            cart_list: result.data.data.cartItems,
            fullAmount: result.data.data.fullAmount,
            discount: result.data.data.discount,
            amtToPay: result.data.data.totalAmount,
            loyaltyEnabled: result?.data?.data?.loyaltyEnabled,
            totalLoyaltyPoints: result?.data?.data?.totalLoyaltyPoints,
            loyaltyPoints: result?.data?.data?.loyaltyPoints,
            pointsEarned: result?.data?.data?.pointsEarned,
          }
          setCartData(result.data.data.cartItems);
          if (result?.data?.data?.cartItems?.length === 0)
            dispatch(setState({ key: "totalCount", value: 0 }));
          setPaymentMethod(result.data.data.paymentMethod);
          setPaymentOptions(result?.data?.data?.payuPaymentMethods);
          localStorage.setItem("cartData", JSON.stringify(result.data.data.cartItems))
          updateMultipleStoreCart(result.data.data.cartItems);
          setLogisticData(data);
          if (value && value === 1) {
            setRewardsCheck(true);
          } else if (value && value === 2) {
            setRewardsCheck(false);
          }
        } else if (result.data.message === 'Network Error') {
          // Network error
        }
      })
      .catch(err => { console.log(err); setIsLoading(false); })
      .finally(() => setIsLoading(false))
  }

  const getLogisticChargeFromCart = async (value) => {
    setIsLoading(true)
    let orderAmount = value;
    const token = localStorage.getItem('sessionId');
    const cartData = localStorage.getItem('cartData')
    let cartDataItems = cartData ? JSON.parse(cartData) : []
    let newCart = cartDataItems;
    newCart.map(ndata => {
      ndata.category = ndata.s1category;
      if (!ndata?.rate) {
        ndata.rate = ndata?.price
      }
    })

    console.log('cartData : ', cartData);
    const data = {
      merchantNo: PhoneNumber,
      order_amount: orderAmount,
      ulatitude: currentLat,
      ulongitude: currentLng,
      eligibility_of_checkout: isCheckout,
      is_from_app: 1,
      cartItems: newCart,
      useLoyaltyPoints: rewardsCheck ? true : false,
    }
    console.log('cart data======> ', '  ' + JSON.stringify(data))

    await ApiService.getUserLogisticCharge(data, token)
      .then(async result => {
        console.log('data======> ', '  ' + (result.data))
        setIsLoading(false)
        if (result?.data?.code == 0) {
          let data = {}
          data = {
            deliveryCharge: result.data.data.totalLogistic,
            govtTax: result.data.data.govt_charge,
            payAmount: result.data.data.amtToPay,
            cashback: result.data.data.userOffer,
            cgst: result.data.data.cgstOnLogistic,
            sgst: result.data.data.sgstOnLogistic,
            logisticCharge: result.data.data.logisticCharge,
            distance: result.data.data.distanceInKM,
            deliveryMsg: result.data.data.btnMsg,
            orderMsg: result.data.data.orderMsg,
            userOffer: result.data.data.userOffer ? result.data.data.userOffer : 0,
            nightChargeMsg: result.data.data.nightChargeMsg,
            nightCharges: result.data.data.nightCharges,
            offerText: result.data.data.offerText ? result.data.data.offerText : 0,
            fullAmount: result.data.data.fullAmount,
            discount: result.data.data.discount,
            amtToPay: result.data.data.totalAmount,
            loyaltyEnabled: result?.data?.data?.loyaltyEnabled,
            totalLoyaltyPoints: result?.data?.data?.totalLoyaltyPoints,
            loyaltyPoints: result?.data?.data?.loyaltyPoints,
            pointsEarned: result?.data?.data?.pointsEarned,
          }
          setCartData(result.data.data.cartItems);
          if (result?.data?.data?.cartItems?.length === 0)
            dispatch(setState({ key: "totalCount", value: 0 }));
          localStorage.setItem("cartData", JSON.stringify(result.data.data.cartItems))
          updateMultipleStoreCart(result.data.data.cartItems);
          setLogisticData(data);
        } else if (result.message === 'Network Error') {
          // Network error
        }
      })
      .catch(err => { console.log(err); setIsLoading(false); })
  }

  const getaddressesAndLogistics = async (value) => {
    setIsLoading(true);
    const token = localStorage.getItem('sessionId')
    await ApiService.getaddresses(token)
      .then(async result => {
        let lat = null;
        let lng = null;

        // console.log('order list====>', result)
        if (result.data.message === 'Invalid Token') {
          console.log('logout called')
        }
        if (result?.data?.code == 0) {
          const arr = []
          let prAddress = {}
          for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].sequence !== 0) {
              arr.push(result.data.data[i])
              if (result.data.data[i].sequence === Number(result.data.priority)) {
                setCurrentLat(result.data.data[i].latitude);
                setCurrentLng(result.data.data[i].longitude);
                lat = result.data.data[i].latitude;
                lng = result.data.data[i].longitude;
                if (result.data.data[i].label !== '' && result.data.data[i].label !== 'null') {
                  if (result.data.data[i].label !== 'Others') {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  } else {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  }
                } else if (result.data.data[i].city !== 'null' && result.data.data[i].city !== '') {
                  prAddress = {
                    label: result.data.data[i].city,
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                } else {
                  prAddress = {
                    label: "Others",
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                }
                setPriorityAddress(prAddress)
                console.log("prADDr", prAddress)
              } else if (Number(result.data.priority) === 0 && result.data.data.length > 1) {
                setPriorityAddress('currentLocation')
              }
            }

          }
          console.log(result)
          let tempArr = arr.sort((a, b) => b.sequence - a.sequence);
          setAddressData(tempArr)
          let logValue = {}
          if (value) {
            logValue = {
              lat: lat,
              lng: lng,
              val: value
            }
          } else {
            logValue = {
              lat: lat,
              lng: lng,
              val: totalPrice
            }
          }
          console.log("LogValue==>", logValue)
          getLogisticCharge(logValue)
        } else if (result.message === 'Network Error') {
          setIsLoading(false);
        }

      }).catch((error) => {
        setIsLoading(false);
        console.log(error)
      })
    // .finally(() => setIsLoading(false));
  }

  const handleCustomProduct = (event, index) => {
    let input = event.target;
    if (input.value.includes('  ')) {
      input.value = input.value.replace(/\s+/g, ' ');
    }
    if (input.value.charAt(0) === ' ') {
      input.value = input.value.substring(1);
    }
    const newInputValues = [...customInstructions]
    let text = input.value;
    newInputValues[index] = text
    if (text !== '' && text !== ' ' && index === newInputValues.length - 1) {
      newInputValues.push('')
    } else if (text === '' && index < newInputValues.length - 1) {
      newInputValues.splice(index, 1)
    }
    setCustomInstructions(newInputValues);

  }

  const handleSendOrder = (phNumber) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'send_order',
      'merchant_id': merchId,
      'shop_name': businessName
    });

    ReactGA.event({
      action: 'Send Order',
      label: 'Website Orders',
      dimension1: merchId,
      dimension2: businessName
    });

    let del = cartData
      .filter((cartData) => cartData.count > 0 && (cartData.variants === undefined || cartData.variants === null || cartData.variants === "" || cartData.variants.length === 0))

      .map((data) => {
        if (data.instruction == "") {
          if (isFruitsAndVegMerchant === "FRUITS" || isFruitsAndVegMerchant === "GROCERY") {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20%20`;
          } else {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20%20`;
          }
        } else {
          if (isFruitsAndVegMerchant === "FRUITS") {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20` + "%20(" + data.instruction + ")";
          } else {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20` + "%20(" + data.instruction + ")";
          }
        }
      })
      .join("%0a");
    del = del.replace("&", "%26");

    for (let i = 0; i < cartData?.length; i++) {
      if (cartData[i].count > 0) {
        if (cartData[i].variants !== undefined && cartData[i].variants !== null && cartData[i].variants !== '' && cartData[i].variants.length !== 0) {
          for (let j = 0; j < cartData[i].variants.length; j++) {
            if (cartData[i].variants[j].count > 0) {
              if (cartData[i].variants[j].instruction !== "") {
                del = del + "%0a" + cartData[i].variants[j].count + "%20" + '%78' + "%20" + cartData[i].variants[j].variantName + "%20%20" + `${(cartData[i].variants[j].desc !== "" && cartData[i].variants[j].desc !== null && cartData[i].variants[j].desc !== undefined) ? "(" + cartData[i].variants[j].desc + ")" : ""}` + "(" + cartData[i].variants[j].instruction + ")";
              } else {
                del = del + "%0a" + cartData[i].variants[j].count + "%20" + '%78' + "%20" + cartData[i].variants[j].variantName + "%20%20" + `${(cartData[i].variants[j].desc !== "" && cartData[i].variants[j].desc !== null && cartData[i].variants[j].desc !== undefined) ? "(" + cartData[i].variants[j].desc + ")" : ""}`;
              }
            }
          }
        }
      }
    }

    if (customInstructions.length > 1) {
      let str = '%0a%0aMore Items';
      for (let i = 0; i < customInstructions.length - 1; i++) {
        str = str + "%0a" + customInstructions[i];
      }
      del = del + str;
    }

    console.log(del);
    window.open(`https://api.whatsapp.com/send?phone=91${phNumber}&text=Website order for ${businessName}%0a%0a${del}`);
  };

  const makeCartData = () => {
    let cart = [];
    let cartObj = {}
    cartData.filter((modData) => modData.count > 0)
      .map((data) => {
        if (data.variants && data.variants.length !== 0) {
          if (data.variants[0].count > 0) {
            cartObj = {
              id: data.id,
              image_url: data.image_url,
              item_name: data.variants[0].variantName,
              count: data.variants[0].count,
              rate: data.variants[0].variantPrice * data.variants[0].count,
              discounted_rate: data.variants[0].variantDisPrice ? data.variants[0].variantDisPrice * data.variants[0].count : '',
              instructions: data.variants[0].instruction ? data.variants[0].instruction : '',
              inStock: 0,
            }
            cart.push(cartObj);
          }
          for (let i = 1; i < data.variants.length; i++) {
            if (data.variants[i].count > 0) {
              cartObj = {
                id: data.variants[i].varientId,
                image_url: data.image_url,
                item_name: data.variants[i].variantName,
                count: data.variants[i].count,
                rate: data.variants[i].variantPrice * data.variants[i].count,
                discounted_rate: data.variants[i].variantDisPrice ? (data.variants[i].variantDisPrice * data.variants[i].count) : '',
                instructions: data.variants[i].instruction ? data.variants[i].instruction : '',
                inStock: 0,
              }
              cart.push(cartObj)
            }
          }
        } else {
          cartObj = {
            id: data.id,
            image_url: data.image_url,
            item_name: data.item_name,
            count: data.count,
            rate: data.rate * data.count, //multiply with count
            discounted_rate: data.discounted_rate ? data.discounted_rate * data.count : '', //multiply with count
            instructions: data.instruction ? data.instruction : '',
            inStock: 0,
          }
          cart.push(cartObj);
        }
      })
    return (cart)


  }

  const makePayment = async (trxId) => {
    setLoader(true);
    let cartData = makeCartData();
    const token = localStorage.getItem('sessionId')
    let customInstr = customInstructions;
    customInstr.pop();
    const data = {
      cart_list: cartData,
      merchant_mobile_no: PhoneNumber,
      full_amount: logisticData.fullAmount,
      total_amount: logisticData.amtToPay,
      total_count: totalCount,
      logistic_charge: logisticData.logisticCharge,
      cgst_on_logistic: logisticData.cgst,
      sgst_on_logistic: logisticData.sgst,
      total_logistic: logisticData.deliveryCharge,
      amt_to_pay: logisticData.payAmount,
      govt_gst: logisticData.govtTax,
      is_from_app: 1,
      extra_cart_items: customInstr,
      latest_apk: 0,
      user_offer_amt: logisticData.userOffer,
      nightCharges: logisticData.nightCharges,
      transactionId: paymentMethod === 'PAYU' ? trxId : '',
      loyaltyPoints: logisticData?.loyaltyPoints || 0,
      pointsEarned: logisticData?.pointsEarned || 0,
    }
    console.log("paynow data", data)
    await ApiService.makePayment(data, token)
      .then(async result => {
        if (result?.data?.code == 0) {
          localStorage.setItem("orderId", result.data.data.orderId);
          const url = result.data.data.paymentLink;
          // history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')
          if (logisticData?.payAmount == 0) {
            setTimeout(() => {
              setLoader(false);
              getChannelDetailsv3()
            }, 2000)
          } else if (paymentMethod === 'PAYU') {
            handleFormSubmit();
            setTimeout(() => {
              setLoader(false);
            }, 5000)
          } else {
            window.location.href = url;
            setTimeout(() => {
              setLoader(false);
            }, 5000)
          }
          // window.open(url, "_blank")
          // setLinkOpening(true);
        } else {
          setLoader(false);
          toast(result?.data?.message, { autoClose: 500 })
        }
      })
      .catch(err => { console.log(err); setLoader(false); toast("Something went wrong. Please try again.", { autoClose: 500 }) });
  }

  const generateHashcodeForWeb = async () => {
    setLoader(true);
    const data = {
      amount: logisticData.payAmount ? logisticData.payAmount : totalPrice,
      productInfo: "websiteOrder",
      firstName: localStorage.getItem("User"),
      udf1: localStorage.getItem("webUrl"),
      udf2: localStorage.getItem("webUrl"),
    }
    try {
      const result = await ApiService.generateHashcodeForWeb(data);
      if (result?.data?.code == 0) {
        console.log("hashResult", result)
        if (inputRefs.current.key) inputRefs.current.key.value = result.data.data.payuKey;
        if (inputRefs.current.txnid) inputRefs.current.txnid.value = result.data.data.txnId;
        if (inputRefs.current.productinfo) inputRefs.current.productinfo.value = "websiteOrder";
        if (inputRefs.current.amount) inputRefs.current.amount.value = logisticData.payAmount ? logisticData.payAmount : totalPrice;
        if (inputRefs.current.email) inputRefs.current.email.value = '';
        if (inputRefs.current.firstname) inputRefs.current.firstname.value = localStorage.getItem("User");
        if (inputRefs.current.lastname) inputRefs.current.lastname.value = '';
        if (inputRefs.current.surl) inputRefs.current.surl.value = `${APIConfig.baseUrl}payuWebSuccess`;
        if (inputRefs.current.furl) inputRefs.current.furl.value = `${APIConfig.baseUrl}payuWebFailure`;
        if (inputRefs.current.phone) inputRefs.current.phone.value = '';
        if (inputRefs.current.hash) inputRefs.current.hash.value = result.data.data.hashCode;
        await makePayment(result.data.data.txnId);
      } else {
        setLoader(false);
      }
    } catch (error) {
      alert(error.message)
    } finally {
    }
  }

  const paymentProcess = async () => {
    if (priorityAddress === '') {
      toast("Add an address to continue", { autoClose: 500 })
    } else if (priorityAddress === 'currentLocation') {
      toast("Select an address to continue", { autoClose: 500 })
    } else {
      if (paymentMethod === 'PAYU' && logisticData?.payAmount != 0) {
        await generateHashcodeForWeb();
      } else {
        makePayment();
      }
    }


  }

  const saveInstruction = () => {
    if (instructionFromHome) {
      if (cartData[instructionIndex].variants && cartData[instructionIndex].variants.length !== 0) {
        cartData[instructionIndex].variants.map((variant => {
          variant.instruction = instruction;
        }))
      }
      cartData[instructionIndex].instruction = instruction;
      let filterdata = [...cartData];
      setCartData(filterdata);
      localStorage.setItem("cartData", JSON.stringify(filterdata));
      updateMultipleStoreCart(filterdata);
      setModalVisible(false);
    } else {
      if (instructionFromCart) {
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let filterdata = [...cartData];
        setCartData(filterdata);
        localStorage.setItem("cartData", JSON.stringify(filterdata));
        updateMultipleStoreCart(filterdata);
        setModalVisible(false);
      } else {
        // modificationData[instructionIndex].variants[variantIndex].instruction = instruction;
        // let filterdata = [...modificationData];
        // setModificationData(filterdata);
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let cart = [...cartData];
        setCartData(cart);
        localStorage.setItem("cartData", JSON.stringify(cart));
        updateMultipleStoreCart(cart);
        setModalVisible(false);
      }
    }
  };

  const updateMultipleStoreCart = (cart) => {
    const multiStoreCart = JSON.parse(localStorage.getItem("multi-store-cart"));
    if (multiStoreCart) {
      const currentStoreCartIndex = multiStoreCart.findIndex(data => data.merchantId === localStorage.getItem("selected-store-id"))
      if (currentStoreCartIndex !== -1) {
        multiStoreCart[currentStoreCartIndex].cartData = cart;
        localStorage.setItem("multi-store-cart", JSON.stringify(multiStoreCart));
      } else {
        multiStoreCart.push({
          merchantId: merchId,
          cartData: cart,
        })
        localStorage.setItem("multi-store-cart", JSON.stringify(multiStoreCart));
      }
    } else {
      const multCart = [{
        merchantId: merchId,
        cartData: cart,
      }]
      localStorage.setItem("multi-store-cart", JSON.stringify(multCart));
    }
  }

  const addCount = (count, id) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Add to Cart',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'Add to Cart',
      label: 'Adding products to cart',
      dimension1: merchId,
      dimension2: businessName,
    });
    count = count + 1;
    const index2 = cartData.map((item) => item.id).indexOf(id); // Found the object index

    cartData[index2].count = count;
    const modifiedIndex = cartData.map((item) => item.id).indexOf(id);
    if (modifiedIndex !== -1) {
      cartData[modifiedIndex].count = count
      let filterdata = [...cartData];
      setCartData(filterdata);
    }
    let cart = [...cartData]
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    updateMultipleStoreCart(cart);
    // setProductCount(count);

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : (data.rate === 0 ? 0 : data.count * parseFloat(data.rate))) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }));

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.rate) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    getLogisticChargeFromCart(Sumprice);
  };

  const decreaseCount = (count, id, value) => {
    count = count - 1;
    if (value && value === "fromCart") {
      const index2 = cartData.map((item) => item.id).indexOf(id); // Found the object index
      if (count === 0) {
        cartData.splice(index2, 1);
      } else {
        cartData[index2].count = count;
      }
      const modifiedIndex = cartData.map((item) => item.id).indexOf(id);
      if (modifiedIndex !== -1) {
        cartData[modifiedIndex].count = count
        let filterdata = [...cartData];
        setCartData(filterdata);
      }
      let cart = [...cartData]
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
      updateMultipleStoreCart(cart);
      // setProductCount(count);
    }

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.rate)) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }));

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.rate) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
    getLogisticChargeFromCart(Sumprice);
  };

  const addCountForVariant = (count, id, parentId) => {
    count = count + 1
    let cart = cartData;
    const parentIndex = cartData.map((item) => item.id).indexOf(parentId);
    const varIndex = cartData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
    cartData[parentIndex].variants[varIndex].count = count
    cartData[parentIndex].count++
    const cartIndex = cart.map((item) => item.id).indexOf(parentId);
    if (cartIndex !== -1) {
      cart[cartIndex].count = cartData[parentIndex].count
      cart[cartIndex].variants[varIndex].count = count
    } else {
      cart.push(cartData[parentIndex])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    updateMultipleStoreCart(cart);
    // setProductCount(productCount + 1)

    let filterdata = [...cartData];
    setCartData(filterdata);

    // localStorage.setItem("modifiedData", JSON.stringify(cartData));

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }

    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.rate)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }));
    getLogisticChargeFromCart(Sumprice);

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.rate) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const decreaseCountForVariant = (count, id, parentId) => {
    count = count - 1

    let cart = cartData;
    const parentIndex = cartData.map((item) => item.id).indexOf(parentId);
    console.log("THIS EE", cartData[parentIndex])
    const varIndex = cartData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
    const cartIndex = cart.map((item) => item.id).indexOf(parentId);
    console.log("CART", cart)
    console.log("CARTState", cartData)
    if (count === 0) {
      if (cartData[parentIndex].count === 1) {
        cart.splice(cartIndex, 1);
      } else {
        cartData[parentIndex].variants[varIndex].count = count
        cartData[parentIndex].count--
      }
    } else {
      cartData[parentIndex].variants[varIndex].count = count
      cartData[parentIndex].count--
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    updateMultipleStoreCart(cart);
    // setProductCount(productCount - 1)
    let filterdata = [...cartData];
    setCartData(filterdata);
    // localStorage.setItem("modifiedData", JSON.stringify(cartData));

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.rate)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }));
    getLogisticChargeFromCart(Sumprice);

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.rate) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const handleChangeAddress = async (address) => {
    const data = { priority: address.sequence }
    const token = localStorage.getItem('sessionId')
    await ApiService.updateUserAddress(data, token)
      .then(async result => {
        if (result?.data?.code == 0) {
          let prAddress = {};
          if (address.label !== '' && address.label !== 'null') {
            if (address.label !== 'Others') {
              prAddress = {
                label: address.label,
                address: address.address_line2
              }
            } else {
              prAddress = {
                label: address.label,
                address: address.address_line1
              }
            }
          } else if (address.city !== 'null' && address.city !== '') {
            prAddress = {
              label: address.city,
              address: address.address_line2
            }
          } else {
            prAddress = {
              label: "Others",
              address: address.address_line2
            }
          }
          setPriorityAddress(prAddress)
          setCurrentLat(address.latitude);
          setCurrentLng(address.longitude);
          getLogisticCharge({
            lat: address.latitude,
            lng: address.longitude,
            val: totalPrice
          });
          console.log("selected address==>", address)
        } else {
          console.log("error")
        }
      })
      .catch(err => { console.log(err); toast("Couldn't update address. Please try again.", { autoClose: 500 }) })

  }

  const getChannelDetailsv3 = async (fromValue) => {
    setIsLoading(true);
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchId,
    }
    await ApiService.getChannelDetailsv3(data, token)
      .then(async result => {
        setIsLoading(false);
        if (result?.data?.data?.is_checkout_order == 0) {
          if ((result?.data?.data?.delivery_mode == 0 && result.data.data.shipment_status === 5) || result.data.data.overall_status === 1 || result.data.data.PaymentDone === 1) {
          } else {
            dispatch(setState({ key: "summaryData", value: { data: result.data.data, destinationLatlng: result.data.destinationLatlng, originLatlng: result.data.originLatlng } }));
            localStorage.removeItem("cartData")
            if (multipleStores.length > 1) {
              const multiStoreCart = JSON.parse(localStorage.getItem("multi-store-cart"));
              if (multiStoreCart) {
                const storeIndex = multiStoreCart.findIndex(data => data?.merchantId === merchId)
                if (storeIndex !== -1) {
                  // Remove the object at the found index
                  multiStoreCart.splice(storeIndex, 1);
                  localStorage.setItem("multi-store-cart", JSON.stringify(multiStoreCart));
                }
              }
            }
            history.replace(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/order-summary` : '/order-summary')

          }
        }
      })
  }

  function hasSingleNonEmptyValue(arr) {
    const nonEmptyValues = arr.filter(value => value !== '');
    return nonEmptyValues.length === 1;
  }

  const handleFormSubmit = () => {
    if (payuFormRef.current) {
      payuFormRef.current.submit();
    }
  };

  return (
    <div className={`cart-whole-container ${totalCount > 0 ? '' : 'h-100'}`}>
      {isLoading && (
        <LoadingSpinner />
      )}
      <ToastContainer className='toast-container' hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
      <form ref={payuFormRef} action='https://secure.payu.in/_payment' method='post'>
        <input type="hidden" name="key" ref={el => inputRefs.current.key = el} />
        <input type="hidden" name="txnid" ref={el => inputRefs.current.txnid = el} />
        <input type="hidden" name="productinfo" ref={el => inputRefs.current.productinfo = el} />
        <input type="hidden" name="amount" ref={el => inputRefs.current.amount = el} />
        <input type="hidden" name="email" ref={el => inputRefs.current.email = el} />
        <input type="hidden" name="firstname" ref={el => inputRefs.current.firstname = el} />
        <input type="hidden" name="lastname" ref={el => inputRefs.current.lastname = el} />
        <input type="hidden" name="surl" ref={el => inputRefs.current.surl = el} />
        <input type="hidden" name="furl" ref={el => inputRefs.current.furl = el} />
        <input type="hidden" name="phone" ref={el => inputRefs.current.phone = el} />
        <input type="hidden" name="hash" ref={el => inputRefs.current.hash = el} />
        <input type="hidden" name="udf1" value={`${localStorage.getItem("webUrl")}`} />
        <input type="hidden" name="udf2" value={`${localStorage.getItem("webUrl")}`} />
        {paymentOptions && (
          <input type="hidden" name="enforce_paymethod" value={paymentOptions} />
        )}
      </form>

      <div className='cart-container'>
        <div className="cart_dialog_header">
          <FontAwesomeIcon className='start-element' onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')} icon={faChevronLeft} style={{ color: '#125d8d', height: '30px' }} />
          <div className="cart-header-container">
            <span className="cart_dialog_header_title">Cart</span>
            <p className="delivery-time-text">Delivery in 30-45 mins</p>
          </div>
        </div>
        {totalCount > 0 ? (
          <>
            <div onClick={() => history.push(`/${localStorage.getItem("fullurl")}`)} className='shadow-card add-more-container'>
              <div className='cart-shop-display'>
                <img src={picUrl} className='shop-image-cart' alt='NA' />
                <p className='cart-shop-name'>{businessName}</p>
              </div>
              <p className='add-more-text'>+ Add more items</p>
            </div>

            {(logisticData.deliveryCharge == 0 || logisticData.cashback != 0) ? (
              <div className='cart-savings-card'>
                <p className='cart-savings-text'>{currency((parseFloat(logisticData.deliveryCharge == 0 ? 35 : 0) +
                  parseFloat(logisticData?.discount > 0 ? logisticData.discount : 0) +
                  parseFloat(logisticData.cashback != 0 ? logisticData.cashback : 0)))} Savings on this order!</p>
              </div>
            ) : ''}

            <div>
              <div className='shadow-card cart-card'>
                {cartData?.length !== 0 && totalCount > 0 ? (
                  cartData?.length > 0 ? (
                    cartData?.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
                      .map((data, index) => {

                        return (
                          <>
                            <div className={`cart_container ${index !== 0 ? 'top-border' : ''}`} key={data.id}>
                              <CardContent style={{ padding: "0px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                                  {data.image_url[0] ? (
                                    <img src={data.image_url[0]} className='cart-pd-image' alt="NA" />
                                  ) : (
                                    <div className='cart-no-image' />
                                  )}
                                  <div style={{ display: 'flex', marginTop: '2px', paddingRight: '10px' }}>
                                    <img src={data.foodType === "Veg" ? veg_icon : data.foodType === "Non-Veg" ? non_veg_icon : egg_icon} style={{ width: "15px", height: '15px', marginBottom: "1px", display: `${data.foodType !== null && data.foodType !== undefined && data.foodType !== "" ? "block" : "none"}`, marginLeft: '5px' }} alt="food-type" />
                                    <p className="cart_normal_pd_name" >
                                      {data.item_name}
                                    </p>
                                  </div>

                                </div>
                                <span
                                  style={{ paddingBottom: "2px", position: "relative" }}
                                >

                                  <div className='grid-add-box-filled-options'>
                                    <p className="plus_minus_text_non_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => decreaseCount(data.count, data.id, "fromCart")}>-</p>
                                    <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                    <p className="plus_minus_text_non_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => addCount(data.count, data.id, "fromCart")}>+</p>

                                  </div>
                                </span>
                                <div style={{ paddingTop: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '80px', paddingRight: '5px' }}>
                                  {(data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? (
                                    <Typography
                                      noWrap
                                      variant="subtile1"
                                      color="text.secondary"
                                      className="cart_old_price_nodisplay"
                                      style={{ textDecoration: 'line-through' }}
                                    >
                                      {data.rate && data.rate ? ` ${data.count > 0 ? currency(data.rate * data.count) : currency(data.rate)}` : "₹ 0"}
                                    </Typography>
                                  ) : ''}
                                  <Typography
                                    noWrap
                                    variant="subtile1"
                                    color="text.secondary"
                                    className="cart_pd_price"
                                    style={{ color: 'black' }}
                                  >
                                    {data.rate && data.rate ? ` ${data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? currency(data.discounted_rate * data.count) : currency(data.rate * data.count)) : ((data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? currency(data.discounted_rate * data.count) : currency(data.rate))}` : "₹ 0"}
                                  </Typography>

                                </div>

                              </CardContent>
                              <CardContent style={{ padding: 0 }}>

                                {/* {data.instruction === "" ? (
                                  <div style={{ paddingLeft: '10px' }} class="special_instruction">
                                    <div className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, true)}>
                                      <div style={{ color: '#125d8d' }} className="cart_plustext">+ </div>
                                      <div style={{ color: '#808081' }} className="cart_specialInstructionText">Special Instructions</div>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ paddingLeft: '10px' }} className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, true)}>
                                    <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                    <p className="specialEditText">{data.instruction} </p>
                                  </div>
                                )} */}



                              </CardContent>

                            </div>
                            {/* <Divider style={{opacity: 0.2}} className="dividers" /> */}
                          </>
                        );
                      })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        color: "blue",
                        fontWeight: "bold",
                        flexDirection: "column",
                        border: "2px solid white",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography variant="caption" noWrap color="text.secondary" style={{ color: "black", textAlign: "center" }}>
                        No product available
                      </Typography>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      color: "blue",
                      fontWeight: "bold",
                      flexDirection: "column",
                      border: "2px solid white",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="caption" noWrap color="text.secondary" style={{ color: "black", textAlign: "center" }}>
                      Your cart is empty{" "}
                    </Typography>
                  </div>
                )}
                {cartData?.length > 0 && totalCount > 0 ? (
                  cartData?.length > 0 ? (
                    cartData?.map((modData, index) => (
                      (modData.variants && modData.variants.length !== 0) ? (
                        modData.variants.filter((data) => data.count > 0)
                          .map((data, varIndex) => {
                            return (
                              <>
                                {data.count > 0 ? (

                                  <>
                                    <div className={`cart_container ${(cartData.filter((data) => !data.variants || data.variants.length === 0).length === 0 && varIndex === 0) ? '' : 'top-border'}`} key={data.id}>
                                      <CardContent style={{ padding: "0px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                                          {modData.image_url[0] ? (
                                            <img src={modData.image_url[0]} className='cart-pd-image' alt="NA" />
                                          ) : (
                                            <div className='cart-no-image' />
                                          )}
                                          <div style={{ display: 'flex', marginTop: '2px', paddingRight: '10px' }}>
                                            <img src={modData.foodType === "Veg" ? veg_icon : modData.foodType === "Non-Veg" ? non_veg_icon : egg_icon} style={{ width: "15px", height: '15px', marginBottom: "1px", display: `${modData.foodType !== null && modData.foodType !== undefined && modData.foodType !== "" ? "block" : "none"}`, marginLeft: "5px", marginBottom: '5px' }} alt="food-type" />
                                            <p className="cart_normal_pd_name" variant="h6" color="text.secondary" >
                                              {data.variantName}
                                            </p>

                                          </div>


                                        </div>
                                        <span
                                          style={{ paddingBottom: "2px", position: "relative" }}
                                        >

                                          <div className='grid-add-box-filled-options'>
                                            <p className="plus_minus_text_non_grid" onClick={() => decreaseCountForVariant(data.count, data.varientId, data.id, "fromCart")}>-</p>
                                            <p className="product_count2">{data.count}</p>
                                            <p className="plus_minus_text_non_grid" onClick={() => addCountForVariant(data.count, data.varientId, data.id)}>+</p>
                                          </div>
                                        </span>
                                        <div style={{ paddingTop: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '80px', paddingRight: '5px' }}>


                                          {(data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? (
                                            <Typography
                                              noWrap
                                              variant="subtile1"
                                              color="text.secondary"
                                              className="cart_old_price_nodisplay"
                                              style={{ textDecoration: 'line-through' }}
                                            >
                                              {data.variantPrice && data.variantPrice ? ` ${data.count > 0 ? currency(data.variantPrice * data.count) : currency(data.variantPrice)}` : "₹ 0"}
                                            </Typography>
                                          ) : ''}
                                          <Typography
                                            noWrap
                                            variant="subtile1"
                                            color="text.secondary"
                                            className="cart_pd_price"
                                            style={{ color: 'black' }}
                                          >
                                            {data.variantPrice && data.variantPrice ? ` ${data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? currency(data.variantDisPrice * data.count) : currency(data.variantPrice * data.count)) : ((data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? currency(data.variantDisPrice) : currency(data.variantPrice))}` : "₹ 0"}
                                          </Typography>

                                        </div>

                                      </CardContent>
                                      {/* <CardContent style={{ padding: 0 }}>
                                      {data.instruction === "" ? (
                                        <div style={{ paddingLeft: '10px' }} class="special_instruction">
                                          <div className="maincard_instruction1" onClick={() => modalopen(data.varientId, data.instruction, false)}>
                                            <div style={{ color: '#125d8d' }} className="cart_plustext">+ </div>
                                            <div style={{ color: '#808081' }} className="cart_specialInstructionText">Special Instructions</div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div style={{ paddingLeft: '10px' }} className="edit_instruction" onClick={() => modalopen(data.varientId, data.instruction, false)}>
                                          <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                          <p className="specialEditText">{data.instruction} </p>
                                        </div>
                                      )}

                                    </CardContent> */}

                                    </div>
                                    {/* <Divider className="dividers" /> */}
                                  </>

                                ) : ''}
                              </>
                            )
                          })
                      ) : ''
                    )
                    )) : '') : ''}
              </div>

              {/* {cartData.length !== 0 ? (
                <div className="custom_product_container">
                  <p onClick={() => setInfoPopUp(true)} className="cutom_pd_header">Did not find all items? <span className="proxima-regular-text write_here_text">Write here</span><img src={info} className="info_img" alt="info" /></p>
                  <div className="shadow-card">
                    {customInstructions.length && customInstructions.map((value, index) => (
                      <div id={`custom_pd${index}`} className="custom_pd">
                        <div className="green_bullet" />
                        <input value={value} className="custom_pd_input" placeholder="Add item" onChange={(event) => handleCustomProduct(event, index)} onInput={(e) => { e.target.value = e.target.value.toString().slice(0, 50); }} />
                      </div>
                    ))}
                  </div>
                </div>
              ) : ''} */}
              {logisticData?.pointsEarned ? (
                <div className='shadow-card cart-earnings-container'>
                  <p className="rewards-rupee-icon">₹</p>
                  <p className='cart-earnings-text'>You will earn {logisticData?.pointsEarned} points on this order</p>
                </div>
              ) : ''}

              <p className='cutom_pd_header'>Bill Details</p>
              <div style={{ display: `${totalCount > 0 ? 'block' : 'none'}` }} className='shadow-card bill_container'>
                <div>
                  {oldTotalPrice !== totalPrice && (
                    <div className='cart-bill-data-container'>
                      <p className='bill_subtext'>MRP Total</p>
                      <p style={{ color: 'black' }} className='bill_amount'>{logisticData.fullAmount ? currency(logisticData.fullAmount) : "₹ 0"}</p>
                    </div>
                  )}

                  {savings !== 0 && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                      <p className='bill_subtext'>Savings</p>
                      <p style={{ color: '#2bb673' }} className='bill_amount'>- {logisticData.discount ? currency(logisticData.discount) : "₹ 0"}</p>
                    </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                    <p className='bill_subtext'>Item Total</p>
                    <p style={{ color: 'black' }} className='bill_amount'>{logisticData.amtToPay ? currency(logisticData.amtToPay) : "₹ 0"}</p>
                  </div>
                  <div className='cart_divider' />
                  <div className='cart-bill-data-container'>
                    <p className='bill_subtext'>Delivery Charges (for {logisticData.distance}km)</p>
                    <p style={{ color: 'black' }} className='bill_amount'>{logisticData.deliveryCharge ? currency(logisticData.deliveryCharge) : '₹0'}</p>
                  </div>
                  {(logisticData.nightChargeMsg && logisticData.nightChargeMsg !== 'null') && (
                    <p className="cashback_subtext">{logisticData.nightChargeMsg}</p>
                  )}
                  {(logisticData.deliveryMsg && logisticData.deliveryMsg !== "Your location is outside seller's delivery area") ? (
                    <p className="cashback_subtext">{logisticData.deliveryMsg && logisticData.deliveryMsg}</p>
                  ) : (
                    <p className="cashback_subtext">{logisticData.orderMsg && logisticData.orderMsg}</p>
                  )}
                  {(logisticData.govtTax && logisticData.govtTax != 0) && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                      <p className='bill_subtext'>Govt Taxes</p>
                      <p style={{ color: 'black' }} className='bill_amount'>{logisticData.govtTax ? currency(logisticData.govtTax) : "₹ 0"}</p>
                    </div>
                  )}
                  {(logisticData.userOffer && logisticData.userOffer != 0) ? (
                    <>
                      <div className='cart_divider' />
                      <div className='cart-bill-data-container'>
                        <p className="bill_amount_bold" style={{ color: '#2bb673', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>{logisticData?.offerText}</p>
                        <p className='bill_amount_bold' style={{ color: '#2bb673' }}>{'- ' + currency(logisticData.userOffer)}</p>
                      </div>
                      {/* <p className="cashback_subtext">Will be credited within 24 hours</p> */}
                    </>
                  ) : ''}
                  <div className='cart_divider' />
                  {logisticData?.loyaltyEnabled && logisticData?.totalLoyaltyPoints != 0 && (
                    <div className='cart-bill-data-container'>
                      <div className="rewards-checkbox-container">
                        {/* <Checkbox
                        className="rewards-checkbox"
                          sx={{
                            color: 'green',
                            '&.Mui-checked': {
                              color: 'yellow',
                            },
                          }}
                        /> */}
                        <input checked={rewardsCheck} onChange={() => getLogisticCharge(rewardsCheck ? 2 : 1)} type='checkbox' className="rewards-checkbox" />
                        <div onClick={() => getLogisticCharge(rewardsCheck ? 2 : 1)}>
                          <p className='cancellation-terms-header green-text m-0'>Redeem Reward Points</p>
                          <p className='rewards-balance-text'>Points Balance: {logisticData?.totalLoyaltyPoints}</p>
                        </div>
                      </div>
                      <p className='bill_amount_bold green-text'>- {currency(logisticData?.loyaltyPoints)}</p>
                    </div>
                  )}
                  <div className='cart-bill-data-container'>
                    <p className='bill_amount_bold' style={{ color: 'black' }}>Amount To Pay</p>
                    <p className='bill_amount_bold' style={{ color: 'black' }}>{logisticData.payAmount ? currency(logisticData.payAmount) : currency(totalPrice)}</p>
                  </div>


                </div>
              </div>

            </div>
            <div className='current-address-container'>
              {cartData?.length !== 0 ? (addressData !== undefined && addressData.length !== 0 && priorityAddress !== 'currentLocation') ? (
                <div onClick={() => setShowSelectAddress(true)} style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: '10px', marginRight: '10px' }} className="priority_address pointer">
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', height: '30px' }} />
                  <div style={{ flex: 1, marginLeft: '10px' }}>
                    <div className='cart-bill-data-container'>
                      <p className="deliver_to_home_text">Deliver to {priorityAddress.label}</p>
                      <p className="address_change_text">CHANGE</p>
                    </div>
                    <p className="addresslines_text proxima-regular-text">{priorityAddress.address}</p>
                    {console.log("Adde", priorityAddress)}
                  </div>
                </div>
              ) : priorityAddress === 'currentLocation' ? (
                <div className="cart_address pointer" onClick={() => setShowSelectAddress(true)}>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <p className="cart_addaddress_text proxima-regular-text">Select address</p>
                    {console.log("addrssData==>", addressData, "==>", priorityAddress)}
                    <p className="cart_addaddress_subtext proxima-regular-text">For delivery of this order</p>
                  </div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', marginRight: '10px', height: '30px' }} />
                </div>
              ) : (
                <div className="cart_address pointer" onClick={() => history.push('/map')}>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <p className="cart_addaddress_text proxima-regular-text">Add address</p>
                    {console.log("addrssData==>", addressData, "==>", priorityAddress)}
                    <p className="cart_addaddress_subtext proxima-regular-text">For delivery of this order</p>
                  </div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', marginRight: '10px', height: '30px' }} />
                </div>
              ) : ''}
            </div>

            {totalCount > 0 &&
              (shopOff === 0 &&
                (logisticData.deliveryMsg !== "Your location is outside seller's delivery area") ? (
                <div onClick={isCheckout === 1 ? () => setWhatsappLayover(true) : ((loader || isLoading) ? null : paymentProcess)} style={{ marginLeft: 0 }} className="cart_bottom_bar">
                  <span className="view_cart_item">Total : {(logisticData.payAmount || logisticData.payAmount == 0) ? currency(logisticData.payAmount) : currency(totalPrice)}</span>
                  <span className="view_cart_text">{loader ? (<ClipLoader color="#36d7b7" />) : (isCheckout === 0 ? `${logisticData.payAmount == 0 ? 'Confirm & Proceed' : 'Pay Now'}` : 'Send Order')}</span>

                </div>
              ) : shopOff === 1 ? (
                <div style={{ marginLeft: 0 }} className="cart_bottom_bar_shop_closed">
                  <p className="shop_closed_text">Shop closed. Currently not accepting orders</p>
                </div>
              ) : (
                <div style={{ marginLeft: 0 }} className="cart_bottom_bar_shop_closed">
                  <p className="shop_closed_text">{logisticData.deliveryMsg}</p>
                </div>
              ))}

            <div onClick={() => window.open('https://chattybao.com/CancellationRefundsReturnExchange', '_blank')} className='shadow-card cart-terms-container'>
              <p className='cancellation-terms-header'>Cancellation Terms & Conditions</p>
              <p className='terms-text'>Order cannot be cancelled once the payment is done</p>
            </div>

            <Dialog open={modalVisible} PaperProps={{ sx: { width: "70%", maxWidth: '307px' } }}>
              <div className="dialog_instruction">
                Special Instructions
                <CloseIcon
                  className="close_btn"
                  onClick={() => {
                    setModalVisible(!modalVisible);
                  }}
                />
              </div>
              <DialogContent className="dialogBox">
                <div
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    border: "2px solid white",
                    borderRadius: "5px",
                    margin: "4px 15px 15px 15px",
                    width: "100%",
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "#0375B0" },
                      },
                    }}
                    id="outlined-basic"
                    className="dialog_instruction_inputs"
                    label="Max 30 characters"
                    variant="outlined"
                    value={instruction}
                    multiline
                    maxRows={2}
                    onChange={(e) => {
                      let input = e.target;
                      if (input.value.includes('  ')) {
                        input.value = input.value.replace(/\s+/g, ' ');
                      }
                      if (input.value.charAt(0) === ' ') {
                        input.value = input.value.substring(1);
                      }
                      setInstruction(input.value);
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 30);
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 16px 0px" }}>
                <button className="dialog_instruction_buttons" onClick={saveInstruction}>
                  Save
                </button>
              </DialogActions>
            </Dialog>

            {/* Extra Instruction Info pop up */}
            <Dialog open={infoPopUp} PaperProps={{ sx: { width: "70%", maxWidth: '307px', padding: '1rem', borderRadius: '1rem' } }}>
              <div className="proxima-regular-text info_content_header">
                How it works ?
                <CloseIcon
                  className="close_btn"
                  onClick={() => {
                    setInfoPopUp(!infoPopUp);
                  }}
                />
              </div>
              <div>
                <div className="info_content">
                  <div className="green_bullet" />
                  <p className="info_content_text">Create your list</p>
                </div>
                <div className="info_content">
                  <div className="green_bullet" />
                  <p className="info_content_text">Business responds on chat</p>
                </div>
                <div className="info_content">
                  <div className="green_bullet" />
                  <p className="info_content_text">Separate payment request is sent</p>
                </div>
              </div>
            </Dialog>

            {/* select address layover */}
            <Dialog
              open={showSelectAddress}
              classes={{
                paper: classes.bigscreens,
              }}
              PaperProps={{ sx: addressPopUpStyle }} onClose={() => setShowSelectAddress(!showSelectAddress)}
            >
              <img
                src={close_icon}
                className='address-close-icon'
                onClick={() => {
                  setShowSelectAddress(!showSelectAddress);
                }}
                alt="close"
              />
              <DialogTitle sx={{ m: 0, p: 0 }}>
                <p className="select_address_title">Choose a delivery address</p>
              </DialogTitle>

              <DialogContent className="dialog_content_grid2" style={{ paddingBottom: "0px", marginBottom: 0 }}>
                <div>
                  <div>
                    <div onClick={() => { history.push('/map'); customInstructions.length !== 1 && localStorage.setItem("extraInstructions", JSON.stringify(customInstructions)) }} className='add-address-container'>
                      <div className='plus-container'>
                        <FontAwesomeIcon icon={faPlus} className='plus-icon' />
                      </div>
                      <p className='add-address-text proxima-regular-text'>Add new Address</p>
                    </div>
                    <div className="cart_divider" />
                  </div>
                  {addressData.map((address) => (
                    <>
                      <div onClick={() => { handleChangeAddress(address); setShowSelectAddress(false); }} className='add-address-container'>
                        <div className='plus-container'>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className='map-marker' />
                        </div>
                        <div>
                          <p className='address_head proxima-regular-text'>{address.label ? address.label : 'Others'}</p>
                          <p className="wholeaddress_text proxima-regular-text">{`${address.address_line1 !== '' ? address.address_line1 + ', ' : ''}`}
                            {`${(address.apartment_or_area !== '' && address.apartment_or_area !== null) ? address.apartment_or_area + ', ' : ''}`}
                            {`${address.address_line2 !== '' ? address.address_line2 : ''}`}</p>
                        </div>
                      </div>
                      <div className="cart_divider" />
                    </>

                  ))}

                </div>
              </DialogContent>
            </Dialog>

            {/* Call layover */}

            <Dialog classes={{ paper: classes.botm_dialog }} open={whatsappLayover} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setWhatsappLayover(false)}>
              <img
                src={close_icon}
                className="call_close_btn"
                onClick={() =>
                  setWhatsappLayover(false)
                }
                alt="close"
              />
              <p className="call-contact-us-header">
                Send Order on WhatsApp
              </p>
              <DialogContent className='whatsapp-layover-content'>
                <div className="call-contact-container">
                  {merchantDetails.data.mobile_numbers_enable_on_website && merchantDetails.data.mobile_numbers_enable_on_website.length !== 0 && (
                    merchantDetails.data.mobile_numbers_enable_on_website.map((data, index) => {
                      return (
                        data ? (
                          <>
                            <div onClick={() => handleSendOrder(data)} className="phone-owner-container">
                              <div className='call-number-whole-container'>
                                <div className='call-number-container'>
                                  <img src={call_btn} className='whatsapp-layover-call-icon' alt='call' />
                                  <p className="phone_call_text">{data}</p>
                                </div>
                              </div>
                              {mainMobileVisible ? (
                                <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                              ) : (
                                <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                              )}
                            </div>
                            <div hidden={hasSingleNonEmptyValue(merchantDetails.data.mobile_numbers_enable_on_website)}>
                              <Divider className="phone-number-divider" />
                            </div>
                          </>

                        ) : ''
                      )
                    })
                  )}
                </div>
              </DialogContent>
            </Dialog>

          </>
        ) : (
          <p className='empty-cart-text'>Your cart is empty</p>
        )}

      </div>


    </div>

  )
}

export default CartPage